var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"body-style":{padding: '24px 32px'},"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"标题","labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {rules: [{ required: true, message: '请输入标题' }]}
        ]),expression:"[\n          'name',\n          {rules: [{ required: true, message: '请输入标题' }]}\n        ]"}],attrs:{"name":"name","placeholder":"给目标起个名字"}})],1),_c('a-form-item',{attrs:{"label":"起止日期","labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'buildTime',
          {rules: [{ required: true, message: '请选择起止日期' }]}
        ]),expression:"[\n          'buildTime',\n          {rules: [{ required: true, message: '请选择起止日期' }]}\n        ]"}],staticStyle:{"width":"100%"},attrs:{"name":"buildTime"}})],1),_c('a-form-item',{attrs:{"label":"目标描述","labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          {rules: [{ required: true, message: '请输入目标描述' }]}
        ]),expression:"[\n          'description',\n          {rules: [{ required: true, message: '请输入目标描述' }]}\n        ]"}],attrs:{"rows":"4","placeholder":"请输入你阶段性工作目标"}})],1),_c('a-form-item',{attrs:{"label":"衡量标准","labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'type',
          {rules: [{ required: true, message: '请输入衡量标准' }]}
        ]),expression:"[\n          'type',\n          {rules: [{ required: true, message: '请输入衡量标准' }]}\n        ]"}],attrs:{"rows":"4","placeholder":"请输入衡量标准"}})],1),_c('a-form-item',{attrs:{"label":"客户","labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'customer',
          {rules: [{ required: true, message: '请描述你服务的客户' }]}
        ]),expression:"[\n          'customer',\n          {rules: [{ required: true, message: '请描述你服务的客户' }]}\n        ]"}],attrs:{"placeholder":"请描述你服务的客户，内部客户直接 @姓名／工号"}})],1),_c('a-form-item',{attrs:{"label":"邀评人","labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} },"required":false}},[_c('a-input',{attrs:{"placeholder":"请直接 @姓名／工号，最多可邀请 5 人"}})],1),_c('a-form-item',{attrs:{"label":"权重","labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} },"required":false}},[_c('a-input-number',{attrs:{"min":0,"max":100}}),_c('span',[_vm._v(" %")])],1),_c('a-form-item',{attrs:{"label":"目标公开","labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} },"required":false,"help":"客户、邀评人默认被分享"}},[_c('a-radio-group',{model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("公开")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("部分公开")]),_c('a-radio',{attrs:{"value":3}},[_vm._v("不公开")])],1),_c('a-form-item',[(_vm.value === 2)?_c('a-select',{attrs:{"mode":"multiple"}},[_c('a-select-option',{attrs:{"value":"4"}},[_vm._v("同事一")]),_c('a-select-option',{attrs:{"value":"5"}},[_vm._v("同事二")]),_c('a-select-option',{attrs:{"value":"6"}},[_vm._v("同事三")])],1):_vm._e()],1)],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")]),_c('a-button',{staticStyle:{"margin-left":"8px"}},[_vm._v("保存")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }